import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AdminContext } from '../../AdminContext';

export default function Sidebar() {
    const { token, pWithdrawal, setPWithdrawal, pwtoken, fromLocation, sidebar, setSidebar } = useContext(AdminContext);
    return (
        <>
            <div className="overlay" />
            <aside className={sidebar ? "page-sidebar d-lg-none d-sm-block d-block" : 'd-none'} data-sidebar-layout="stroke-svg">
                <div className="left-arrow" id="left-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-left" />
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul className="sidebar-menu" id="simple-bar">
                        <li className="pin-title sidebar-list p-0">
                            <h5 className="sidebar-main-title">Pinned</h5>
                        </li>
                        <li className="line pin-line" />
                        <li className="sidebar-main-title">General</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/dashboard" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Dashboard</span>
                            </Link>
                        </li>

                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/4x-users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>4x Users</span>
                            </Link>
                        </li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-transcation" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Transcation</span>
                            </Link>
                        </li> */}
                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Income</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/direct-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Direct Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/community-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Community Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/royalty-club-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Royalty Club Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/rank-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Rank Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Token Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Coin Withdrawal</span>
                            </Link>
                        </li> */}
                        <li className="line"> </li>
                        <li className="sidebar-main-title">Node Users Report</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Users</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span> Mining Reward</span>
                            </Link>
                        </li>
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/nbox-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Nbox Reward</span>
                            </Link>
                        </li> */}
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/direct-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Direct Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pool-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pool Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Token Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Coin Withdrawal</span>
                            </Link>
                        </li>
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/ledger" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Ledger</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                <div className="right-arrow" id="right-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-right" />
                    </svg>
                </div>
            </aside>
            <aside className="page-sidebar d-lg-block d-sm-none d-none" data-sidebar-layout="stroke-svg">
                <div className="left-arrow" id="left-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-left" />
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul className="sidebar-menu" id="simple-bar">
                        <li className="pin-title sidebar-list p-0">
                            <h5 className="sidebar-main-title">Pinned</h5>
                        </li>
                        <li className="line pin-line" />
                        <li className="sidebar-main-title">General</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/dashboard" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Dashboard</span>
                            </Link>
                        </li>

                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/4x-users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>4x Users</span>
                            </Link>
                        </li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-transcation" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Transcation</span>
                            </Link>
                        </li> */}
                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Income</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/direct-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Direct Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/community-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Community Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/royalty-club-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Royalty Club Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/rank-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Rank Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Token Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Coin Withdrawal</span>
                            </Link>
                        </li> */}
                        <li className="line"> </li>
                        <li className="sidebar-main-title">Node Users Report</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Users</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking-income" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span> Mining Reward</span>
                            </Link>
                        </li>
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/nbox-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Nbox Reward</span>
                            </Link>
                        </li> */}
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/direct-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Direct Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pool-reward" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pool Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending-withdrawal" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Token Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pending" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Coin Withdrawal</span>
                            </Link>
                        </li>
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/ledger" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Ledger</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                <div className="right-arrow" id="right-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-right" />
                    </svg>
                </div>
            </aside>
        </>
    )
}
