import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import Web3 from "web3";
import axios from "axios";
import { AdminContext } from '../../AdminContext';
import { ethers } from "ethers";
import { formatEther } from 'ethers/lib/utils';

const web3 = new Web3(
    new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
);

export default function Hash() {
    const { rdpkg, fdpkg, tokenrate, formatDateTime, settings, getsmcs, } = useContext(AdminContext);
    const [transactionHash, setTransactionHash] = useState("");
    const [receipt, setReceipt] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [msg, setMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const [showMsg, setShowMsg] = useState(false);

    // console.log("settings.contract", getsmcs);

    // // Create a contract instance
    // const contract = new web3.eth.Contract(settings.contract_abi, settings.contract);
    // console.log("Contract Instance:", contract);

    // async function getTokenPrice() {
    //     try {
    //         // Call the smart contract function to get the token price
    //         const price = await contract.methods.getTokenPrice().call();
    //         console.log("Token Price:", price);
    //     } catch (error) {
    //         console.error("Error fetching token price:", error);
    //     }
    // }

    // // Call the function in an async context or wrap in a Promise
    // (async () => {
    //     await getTokenPrice();
    // })();

    useEffect(() => {
        // const contract = new web3.eth.Contract(settings.contract_abi, settings.contract);
        if (transactionHash) {
            const fetchData = async () => {
                try {
                    console.log("transactionHash", transactionHash);
                    const transaction = await web3.eth.getTransaction(transactionHash);
                    const getTransactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
                    // const price = await settings.contract.methods.getTokenPrice().call();
                    console.log("getTransactionReceipt 12313131:", getTransactionReceipt.logs[0].data);
                    console.log("transaction", transaction);

                    const getBLock = await web3.eth.getBlock(transaction.blockNumber);
                    console.log("getBLock", getBLock);
                    if (!transaction && !getBLock) {
                        setReceipt(null);
                        return;
                    }

                    const abi = [
                        {
                            inputs: [
                                { "internalType": "uint256", "name": "value", "type": "uint256" },
                                { "internalType": "address", "name": "referral", "type": "address" }
                            ],
                            name: "stake",
                            outputs: [],
                            stateMutability: "nonpayable",
                            type: "function"
                        }
                    ];
                    console.log("abi", abi);
                    console.log("transaction.receipt", transaction.receipt);
                    const methodId = transaction.input.slice(0, 10);
                    const params = transaction.input.slice(10);
                    console.log("methodId, params", methodId, params);
                    const functionAbi = abi.find(
                        (func) => web3.eth.abi.encodeFunctionSignature(func) === methodId
                    );
                    console.log("functionAbi", functionAbi);
                    if (!functionAbi) {
                        setReceipt(null);
                        return;
                    }
                    const decodedParams = web3.eth.abi.decodeParameters(
                        functionAbi.inputs,
                        params
                    );
                    console.log("decodedParams", decodedParams);
                    const tokenDecimals = 8;
                    // const rate0 = parseInt(getTransactionReceipt.logs[0].data, 16)
                    const rate0 = ethers.BigNumber.from(getTransactionReceipt.logs[0].data).toString()
                    const rate1 = ethers.BigNumber.from(getTransactionReceipt.logs[2].data).toString()
                    const amount = ethers.BigNumber.from(decodedParams.value).toString()
                    console.log("rate", ethers.BigNumber.from(getTransactionReceipt.logs[1].data).toString());
                    console.log("getTransactionReceipt.logs.data[0]", getTransactionReceipt);
                    // ethers.utils.formatUnits(weiAmount, "ether")
                    console.log("parseInt0", ethers.utils.formatUnits(rate0.toString(), "ether"));
                    console.log("parseInt1", ethers.utils.formatUnits(rate1.toString(), "ether"));

                    const data = {
                        user: transaction.from,
                        ref: decodedParams.referral,
                        pkg: Number(decodedParams.pkg),
                        type: Number(decodedParams._type),
                        token: ethers.utils.formatUnits(rate0.toString(), "ether") + ethers.utils.formatUnits(rate1.toString(), "ether"),
                        amount: ethers.utils.formatUnits(amount.toString(), "ether"),
                        dateTime: new Date(Number(getBLock.timestamp) * 1000),
                    };
                    console.log("data", data);
                    setReceipt(data);
                } catch (err) {
                    console.error("Error fetching transaction data:", err);
                    setReceipt(null);
                }
            };
            fetchData();
        } else {
            setReceipt(null);
        }
    }, [transactionHash]);

    const insertData = async (e) => {
        // console.log("YES");
        // return

        setErrorMessage("");
        setShowError(false);
        setMsg("");
        setShowMsg(false);
        // console("transaction", transactionHash, receipt.user, receipt.ref, receipt.amount, receipt.token, tokenrate);

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transaction",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                hash: transactionHash,
                address: receipt.user,
                ref_address: receipt.ref,
                amount: receipt.amount,
                token: parseFloat(receipt.token).toFixed(2),
                token_rate: Number(ethers.utils.formatUnits(tokenrate, 18)).toFixed(2),
                // dateTime: receipt.dateTime,
                // pkg: receipt.pkg,
                // isrd: receipt.type !== 1,
                // pkg_id:
                //     receipt.type === 1
                //         ? fdpkg[receipt.pkg]?._id
                //         : rdpkg[receipt.pkg]?._id,
            });

            const { resCode, error } = response.data;
            if (resCode === 200) {
                setMsg("Data inserted successfully");
                setShowMsg(true);

                setTransactionHash("");
                setReceipt(null);
            } else {
                setErrorMessage(error || "An unexpected error occurred");
                setShowError(true);
            }
        } catch (err) {
            console.error("Error inserting data:", err);
            setErrorMessage("Network error. Please try again later.");
            setShowError(true);
        }
    };

    useEffect(() => {
        let timer;
        if (showError) {
            timer = setTimeout(() => setShowError(false), 2000);
        }
        if (showMsg) {
            timer = setTimeout(() => setShowMsg(false), 2000);
        }
        return () => clearTimeout(timer);
    }, [showError, showMsg]);


    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        {/* <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Ecommerce Dashboard</h3>
                                </div>
                                <div className="col-sm-6">
                                    <div className='d-flex justify-content-end'>
                                        <button className="btn btn-primary">Deposit</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* Container-fluid starts*/}
                        <div className="container ecommerce-dashboard">
                            <div className="row justify-content-center">
                                <div className="col-6 pt-5">
                                    <div className="card login-dark p-5">
                                        <div>
                                            {/* <div><img className="img-fluid for-light" src="../assets/images/logo/logo.png" alt="looginpage" /><img className="img-fluid for-dark m-auto" src="../assets/images/logo/dark-logo.png" alt="logo" /></div> */}
                                            <div className="login-main">
                                                <form className="theme-form">
                                                    <h2 className="text-center">Pending Transcation</h2>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Hash</label>
                                                        <input className="form-control" type="text" required placeholder="Enter Hash"
                                                            value={transactionHash}
                                                            onChange={(e) =>
                                                                setTransactionHash(e.target.value)
                                                            } />
                                                    </div>
                                                    {receipt && (
                                                        <>
                                                            <div className="d-flex justify-content-between text-break mb-4">
                                                                <span>User Address :</span>
                                                                <span>{receipt.user}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between text-break mb-4">
                                                                <span>Ref. Address :</span>
                                                                <span>{receipt.ref}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between mb-4">
                                                                <span>Amount :</span>
                                                                <span>{receipt.amount}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between mb-4">
                                                                <span>Token :</span>
                                                                <span>{parseFloat(receipt.token).toFixed(4)}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between mb-4">
                                                                <span>Type :</span>
                                                                <span>
                                                                    {/* {receipt.type === 1 ? "FD" : "RD"}  */}
                                                                    Stake</span>
                                                            </div>
                                                            {/* <div className="d-flex justify-content-between text-white text-break mb-4">
                                                                <span>Package :</span>
                                                                <span>
                                                                    {receipt.type === 1
                                                                        ? fdpkg[receipt.pkg]?._id
                                                                        : rdpkg[receipt.pkg]?._id}
                                                                </span>
                                                            </div> */}
                                                            {/* <div className="d-flex justify-content-between text-white mb-4">
                                                                <span>Rate :</span>
                                                                <span>{tokenrate}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between text-white mb-4">
                                                                <span>Date :</span>
                                                                <span>{formatDateTime(receipt.dateTime)}</span>
                                                            </div> */}
                                                        </>
                                                    )}
                                                    {showError && errorMessage && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errorMessage}
                                                        </div>
                                                    )}
                                                    {showMsg && msg && (
                                                        <div className="alert alert-success" role="alert">
                                                            {msg}
                                                        </div>
                                                    )}
                                                    <div className="form-group mb-0 checkbox-checked mb-5">
                                                        <div className="text-end mt-3"><button type='button' className="btn btn-primary btn-block w-100 text-white" onClick={insertData}
                                                            disabled={!receipt}>Submit </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>
        </>
    )
}
