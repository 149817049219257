import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { AdminContext } from "./AdminContext";

const ProtecteRoutes = () => {
  const { token, pWithdrawal, setPWithdrawal, pwtoken, setfromLocation } = useContext(AdminContext);
  const [status, setStatus] = useState(true);
  const location = useLocation();

  const verifyJWTToken = async () => {
    try {
      if (localStorage.getItem("g4fs34t3f4s5a")) {
        await axios.post(process.env.REACT_APP_BACKEND_LINK, {
          method: "verify",
          submethod: "jwttoken",
          key: process.env.REACT_APP_KEY,
          token: localStorage.getItem("g4fs34t3f4s5a") || token
        }).then(res => {
          if (res.data?.status && (res.data?.status === true || res.data?.status === "true")) {
            setStatus(true);
          } else {
            setStatus(false);
          }
        });
      } else {
        setStatus(false);
      }

      // if (localStorage.getItem("2faAuth")) {
      //   await axios.post(process.env.REACT_APP_BACKEND_LINK, {
      //     method: "verify",
      //     submethod: "jwttoken",
      //     key: process.env.REACT_APP_KEY,
      //     token: localStorage.getItem("2faAuth") || pwtoken
      //   }).then(res => {
      //     if (res.data?.status && (res.data?.status === true || res.data?.status === "true")) {
      //       setStatus(true);
      //     } else {
      //       setStatus(false);
      //     }
      //   });
      // } else {
      //   setStatus(false);
      // }
    } catch (err) {
      console.log("err : ", err);
      setStatus(false);
    }
  };

  const check2FAStatus = () => {
    // Check if the user has completed 2FA (stored in localStorage or some global state)
    return localStorage.getItem("2faAuth");
  };

  useEffect(() => {
    if (token) {
      verifyJWTToken();
    } else {
      setStatus(false);
    }
    // if (pwtoken) {
    //   verifyJWTToken();
    // } else {
    //   setStatus(false);
    // }
  }, [token, pwtoken]);
  var referrer = location.pathname;
  console.log("referrer url", location.pathname);
  if (!status) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Check if the current route is /pending-withdrawal and ensure 2FA is completed
  if (location.pathname === "/pending-withdrawal" && !check2FAStatus() || location.pathname === "/pending" && !check2FAStatus()) {
    setfromLocation(referrer);
    setPWithdrawal(true);
    return <Navigate to="/authentication" state={{ from: location }} replace />;
  }



  // useEffect(() => {
  //   if (pwtoken) {
  //     setPWithdrawal(true);
  //   }
  //   else {
  //     setPWithdrawal(false);
  //   }
  // }, [pwtoken]);

  return <Outlet />;
};

export default ProtecteRoutes;
