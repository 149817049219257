import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';
import Web3 from "web3";

export default function Pending() {
    const { formatAddress, settings, web3, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);
    // console.log("2faAuth", localStorage.getItem("2faAuth"));

    const [account, setAccount] = useState(null);
    const [web3c, setWeb3c] = useState(null);
    const [value, setValue] = useState(0);
    const [trdone, settrdone] = useState(true);
    const [selected_address, setselected_address] = useState([]);
    const [selected_address_amt, setselected_address_amt] = useState([]);
    const [selected_address_amt_as, setselected_address_amt_as] = useState([]);

    const connectMetaMask = async () => {
        if (window.ethereum) {
            // MetaMask exists
            try {
                // Request account access if needed
                const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
                console.log("accounts", accounts);
                const web3Instance = new Web3(window.ethereum);

                // Set the web3 instance and the user's account
                setWeb3c(web3Instance);
                setAccount(accounts[0]);

                // console.log("Connected account:", accounts[0]);
            } catch (error) {
                console.error("User denied account access or error occurred:", error);
            }
        } else {
            // MetaMask is not installed
            alert("Please install MetaMask to use this app.");
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "withdrawal",
                    submethod: "getpwcoin",
                    key: process.env.REACT_APP_KEY,
                    search: search,
                    fromdate: fromdate,
                    todate: todate,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    // console.log("user data", res.data.data);
                    setLoading(false);
                    if (res.data.error) {
                        setdata(res.data.data);
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };

    const selectAll = (event) => {
        if (event?.target?.checked) {
            // Reset arrays and total amount to start fresh
            let allSelectedAddresses = [];
            let allSelectedAmountsWei = [];
            let allSelectedAmounts = [];
            let totalAmount = 0;

            // Loop through all data to check each item and accumulate values
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = true;  // Check the checkbox

                const address = item.address;
                const amount = parseFloat(item.amount);  // Assuming 'amount' is the right field for your use case
                const token = parseFloat(item.token);  // Assuming 'amount' is the right field for your use case
                // const token = web3?.utils?.toWei(item.token.toString(), "ether");  // Assuming 'amount' is the right field for your use case
                const amountWei = web3?.utils?.toWei(item.amount, "ether");

                allSelectedAddresses.push(address);
                allSelectedAmountsWei.push(amountWei);
                allSelectedAmounts.push(amount);
                totalAmount += amount;  // Accumulate total amount
            });

            // Update state with new selection
            setselected_address(allSelectedAddresses);
            setselected_address_amt(allSelectedAmountsWei);
            setselected_address_amt_as(allSelectedAmounts);
            setValue(totalAmount);  // Update the total value

        } else {
            // Uncheck all checkboxes and reset state
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = false;  // Uncheck the checkbox
            });

            // Clear state and reset total amount
            setselected_address([]);
            setselected_address_amt([]);
            setselected_address_amt_as([]);
            setValue(0);  // Reset total amount
        }
    };
    const withdrawMultiple = async () => {
        // console.log("settings", settings)
        // console.log("account", account)
        settrdone(false)
        var contract = new web3c.eth.Contract(JSON.parse(settings.withdrawal_abi), settings.withdrawal_contract)

        // 1 - withdrawalProtocol
        // 0 - withdrawal
        console.log("Final", selected_address, selected_address_amt);
        console.log("value", web3.utils.toWei(value, "ether"));

        // await contract.methods.withdrawcoin(selected_address, selected_address_amt, {value: web3.utils.toWei(value, "ether")}).send({ from: account}, //error
        await contract.methods.withdrawcoin(selected_address, selected_address_amt).send({ from: account, gasPrice: '100000000000' },
            async function (err, transactionId) {
                // console.log("tra", transactionId);
                if (err) {
                    settrdone(true)
                }
            }).then(async res => {
                // console.log("Ress", res);

                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "withdrawal",
                        submethod: "getpwmultiplecoin",
                        key: process.env.REACT_APP_KEY,
                        hash: res.transactionHash,
                        address: selected_address,
                        amount: selected_address_amt_as,
                        total: value,
                        type: 1,
                    })
                    .then((res) => {
                        for (let i = 0; i < selected_address.length; i++) {
                            const checkbox = document.getElementById(`chkbox${i}`);
                            if (checkbox) {
                                checkbox.checked = false;
                            }

                        }
                        getData()
                        setselected_address([])
                        setselected_address_amt([])
                        setValue(0)
                        if (res.data.error) {
                            return "";
                        }
                        toastSuccess("Transaction Done")

                    });
            });
        settrdone(true)
    }
    const handleChange = (event, address, amount, token) => {
        if (event?.target?.checked) {
            setselected_address([...selected_address, address]);
            // var amt = web3?.utils?.toWei(amount, "ether")
            // console.log("DA", token);

            const amt = web3.utils.toWei(amount, "ether");  // Assuming 'amount' is the right field for your use case
            // console.log("DA", amt);

            setselected_address_amt([...selected_address_amt, amt]);
            setselected_address_amt_as([...selected_address_amt_as, amount]);
            setValue((prevValue) => prevValue + amount);

        } else {
            const index = selected_address.findIndex(item => item === address);
            if (index !== -1) {
                setselected_address(selected_address.filter((_, ind) => ind !== index));
                setselected_address_amt(selected_address_amt.filter((_, ind) => ind !== index));
                setselected_address_amt_as(selected_address_amt_as.filter((_, ind) => ind !== index));
                setValue((prevValue) => prevValue - amount);

            }
        }
    };
    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);

    useEffect(() => {
        console.log(selected_address);
        console.log(selected_address_amt);
        console.log(selected_address_amt_as);
    }, [selected_address, selected_address_amt])
    return (
        <>
            <>
                <main className="page-wrapper compact-wrapper" id="pageWrapper">
                    <Header />
                    <div className="page-body-wrapper">
                        <Sidebar />
                        <div className="page-body">
                            <div className="container-fluid">
                                <div className="row page-title">
                                    <div className="col-sm-6">
                                        <h3>Pending</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex  pe-5">
                                <div>
                                    <h5 class="py-3 px-5 text-break">🟢{account ? formatAddress(account) : "Not Connected"}</h5>
                                    <h5 class="px-5">Total: {value} {process.env.REACT_APP_TICKER}</h5>
                                    <p class="px-5">
                                        <input className="p-5" type="checkbox" name={`chkbox`} id={`chkbox`} onChange={(ed) => selectAll(ed)} /> Select All</p>
                                </div>
                                <div>
                                    {account ?
                                        <>{trdone ?
                                            <button className="btn btn-success m-1" onClick={() => withdrawMultiple()}>
                                                Give Withdrawal
                                            </button> : <img
                                                src="/assets/images/loader.gif"
                                                style={{ height: "80px" }}
                                                alt="icon"
                                            />
                                        }
                                        </> :
                                        <button className="btn btn-success m-1" onClick={() => connectMetaMask()}>
                                            Connect Metamask
                                        </button>}
                                </div>
                            </div>
                            <hr />
                            {/* Container-fluid starts*/}
                            <div className="container-fluid">
                                <div class="row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-header">
                                            </div>
                                            <div className="table-responsive signal-table p-3">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Type</th>
                                                            <th>Address</th>
                                                            <th>Amount</th>
                                                            {/* <th>Token</th> */}
                                                            {/* <th>Fee</th>
                                                            <th>Total Recieve</th> */}
                                                            {/* <th>Rate</th> */}
                                                            <th>Datetime</th>
                                                            {/* <th>Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data?.length
                                                            ? data.map((e, i) => {
                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                return (
                                                                    <tr>
                                                                        <td >
                                                                            {/* {ind} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.token )} /> */}
                                                                            {ind} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.amount, e.token)} />
                                                                        </td>
                                                                        {/* <td>{ind} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.t_rcv)} /></td> */}
                                                                        <td >
                                                                            Coin Withdrawal
                                                                        </td>
                                                                        <td>
                                                                            <a
                                                                                target="_blank"
                                                                                className="text-primary"
                                                                                href={`${process.env.REACT_APP_EXPLORER}/tx/${e.address}`}
                                                                                rel="noreferrer"
                                                                            >
                                                                                {formatAddress(e.address)}
                                                                            </a>
                                                                            {e.address ? <Copy data={e.address} /> : ""}
                                                                        </td>
                                                                        <td >
                                                                            $ {e.amount} {process.env.REACT_APP_TICKER}
                                                                        </td>
                                                                        {/* <td >
                                                                            {e.token} {process.env.REACT_APP_TICKER}
                                                                        </td> */}
                                                                        {/* <td >
                                                                            {e.fee}
                                                                        </td>
                                                                        <td >
                                                                            {e.t_rcv}
                                                                        </td> */}
                                                                        {/* <td >
                                                                            $ {e.rate}
                                                                        </td> */}

                                                                        <td >
                                                                            {e.datetime}
                                                                        </td>
                                                                        {/* <td>
                                                                            {e.status === true ? (
                                                                                <div className="text-danger">
                                                                                    Over
                                                                                </div>
                                                                            ) : (
                                                                                <div className="text-success">
                                                                                    Active
                                                                                </div>
                                                                            )}
                                                                        </td> */}
                                                                    </tr>
                                                                );
                                                            })
                                                            : ""}
                                                    </tbody>
                                                </table>
                                                {loading ? (
                                                    <div className="text-center">
                                                        <p>Loading...</p>
                                                    </div>
                                                ) : !data || !data.length ? (
                                                    <div className="text-center">
                                                        <p>No data found.</p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className='d-flex justify-content-center py-4'>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    pages={pages}
                                                />
                                                {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Scroll - vertical dynamic Ends*/}
                            </div>
                            {/* Container-fluid starts*/}


                        </div>

                        <Footer />
                    </div>

                </main>

            </>

        </>
    )
}
