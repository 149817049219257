import React, { useContext, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AdminContext } from "../AdminContext";

function QrCodeAuth() {
  const [authNumber, setAuthNumber] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const navigate = useNavigate();
  const { state, location } = useLocation();
  const { setToken, pWithdrawal, pwtoken, setPWToken, fromLocation } = useContext(AdminContext);
  console.log(fromLocation);

  const insertCode = async () => {

    try {

      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber: authNumber,
        username: state.username,
        _id: state._id,
      });

      const { resCode, error, token } = response.data;
      // console.log("Verified", authNumber, state.username, state._id, response.data)
      // console.log("token", token);
      if (resCode === 200) {
        console.log("Verified")
        if (token) {
          setMsg("Verified successfully");
          setShowMsg(true);
          localStorage.setItem("g4fs34t3f4s5a", token); // Set verification flag
          localStorage.setItem(
            "user1f2s24dsaf7",
            JSON.stringify({ username: state.username, id: state._id })
          );
          setToken(token);
          navigate("/dashboard");

        } else {
          setErrorMessage("Unable to generate token.");
          setError(true);
        }
      } else {
        setErrorMessage(error || "Verification failed. Please try again.");
        setError(true);
        // navigate("/authentication");
      }

    } catch (err) {
      console.error("Error during verification:", err);
      setErrorMessage("Network error. Please try again later.");
      setError(true);
      // navigate("/authentication");
    }
  };

  const handle2FACompletion = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber: authNumber,
        username: state.username,
        _id: state._id,
      });


      const { resCode, error, token } = response.data;
      // console.log("Verified", authNumber, state.username, state._id, response.data)
      // console.log("token", token);
      if (resCode === 200) {
        console.log("Verified")
        if (token) {
          setMsg("Verified successfully");
          setShowMsg(true);
          localStorage.setItem("2faAuth", token); // Set verification flag
          // localStorage.setItem(
          //   "user1f2s24dsaf7",
          //   JSON.stringify({ username: state.username, id: state._id })
          // );
          setPWToken(token);
          // navigate("/pending-withdrawal");
          navigate(fromLocation);
          console.log(fromLocation);

        } else {
          setErrorMessage("Unable to generate token.");
          setError(true);
        }
      } else {
        setErrorMessage(error || "Verification failed. Please try again.");
        setError(true);
        // navigate("/authentication");
      }
      // console.log("token", token);
      // if (resCode === 200) {
      //   if (await token) {

      //     localStorage.setItem('2faAuth', 'true');
      //     navigate("/pending-withdrawal");
      //   } else {
      //     setErrorMessage("Unable to generate token.");
      //     setError(true);
      //   }
      // } else {
      //   setErrorMessage(error || "Verification failed. Please try again.");
      //   setError(true);
      //   // navigate("/authentication");
      // }
    } catch (err) {
      console.error("Error during verification:", err);
      setErrorMessage("Network error. Please try again later.");
      setError(true);
      // navigate("/authentication");
    }
  };
  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="login-card login-dark">
            <div>
              {/* <div><img className="img-fluid for-light" src="../assets/images/logo/logo.png" alt="looginpage" /><img className="img-fluid for-dark m-auto" src="../assets/images/logo/dark-logo.png" alt="logo" /></div> */}
              <div className="login-main">
                <form className="theme-form">
                  <div className="form-group">
                    <label className="form-label">
                      Authentication Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setAuthNumber(e.target.value)}
                    />
                  </div>
                  {error && errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  {showMsg && msg && (
                    <div className="alert alert-success" role="alert">
                      {msg}
                    </div>
                  )}
                  <div className="text-center">
                    {/* <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={insertCode}
                    >
                      Log In
                    </button> */}
                    {pWithdrawal ?

                      < button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={handle2FACompletion}
                      >
                        Verify
                      </button>
                      :
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={insertCode}
                      >
                        Log In
                      </button>
                    }
                    <br />
                    <br />
                    <Link to="/dashboard"> Go to Dashboard</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
    // <div className="authincation vh-100">
    //   <div className="container h-100">
    //     <div className="row justify-content-center h-100 align-items-center">
    //       <div className="col-md-6">
    //         <div className="authincation-content">
    //           <div className="row no-gutters">
    //             <div className="col-xl-12">
    //               <div className="auth-form">
    //                 <div className="form-group">
    //                   <label className="form-label">
    //                     Authentication Number
    //                   </label>
    //                   <input
    //                     type="text"
    //                     className="form-control"
    //                     onChange={(e) => setAuthNumber(e.target.value)}
    //                   />
    //                 </div>
    //                 {error && errorMessage && (
    //                   <div className="alert alert-danger" role="alert">
    //                     {errorMessage}
    //                   </div>
    //                 )}
    //                 {showMsg && msg && (
    //                   <div className="alert alert-success" role="alert">
    //                     {msg}
    //                   </div>
    //                 )}
    //                 <div className="text-center">
    //                   <button
    //                     type="button"
    //                     className="btn btn-primary btn-block"
    //                     onClick={insertCode}
    //                   >
    //                     Log In
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default QrCodeAuth;
