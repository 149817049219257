import logo from './logo.svg';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Login from './Components/Login';
import Staking from './Components/Report/Staking';
import Users from './Components/MembersReport/Users';
import StakingIncome from './Components/Report/StakingIncome';
import DirectReward from './Components/Report/DirectReward';
import CommunityReward from './Components/Report/CommunityReward';
import RoyaltyClubReward from './Components/Report/RoyaltyClubReward';
import RankReward from './Components/Report/RankReward';
import Withdrawal from './Components/Report/Withdrawal';
import MemberReport from './Components/MembersReport/MemberReport';
import QrCodeAuth from './Components/QrCodeAuth';
import ProtecteRoutes from './ProtecteRoutes';
import PendingWithdrawal from './Components/Report/PendingWithdrawal';
import Users4x from './Components/MembersReport/Users4x';
import Hash from './Components/Dashboard/Hash';
import Deposit from './Components/Dashboard/Deposit';
import Pending from './Components/Report/Pending';
import NboxReward from './Components/Report/NboxReward';
import PoolReward from './Components/Report/PoolReward';

function App() {
  return (
    <>
      {/* <Dashboard /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Login />} />
            <Route path="/authentication" element={<QrCodeAuth />} />

            <Route element={<ProtecteRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/staking" element={<Staking />} />
              <Route path="/staking-income" element={<StakingIncome />} />
              <Route path="/direct-reward" element={<DirectReward />} />
              <Route path="/nbox-reward" element={<NboxReward />} />
              <Route path="/pool-reward" element={<PoolReward />} />
              <Route path="/community-reward" element={<CommunityReward />} />
              <Route path="/royalty-club-reward" element={<RoyaltyClubReward />} />
              <Route path="/rank-reward" element={<RankReward />} />
              <Route path="/withdrawal" element={<Withdrawal />} />
              <Route path="/pending" element={<Pending />} />
              <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
              <Route path="/users" element={<Users />} />
              <Route path="/4x-users" element={<Users4x />} />
              <Route path="/hash" element={<Hash />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/userreport/:address" element={<MemberReport />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
