import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';

export default function Users4x() {
    const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "user",
                    submethod: "get4xusers",
                    key: process.env.REACT_APP_KEY,
                    search: search,
                    fromdate: fromdate,
                    todate: todate,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    console.log("user data", res.data.data);
                    setLoading(false);
                    if (res.data.error) {
                        setdata(res.data.data);
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Users</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid">
                            <div class="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                        </div>
                                        <div className="table-responsive signal-table p-3">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        {/* <th>W Status</th>
                                                        <th>ROI Status</th> */}
                                                        <th>User Name</th>
                                                        <th>Ref. User Name</th>
                                                        <th>Staking</th>
                                                        <th>Total Team</th>
                                                        <th>Directs</th>
                                                        <th>Rank</th>
                                                        <th>Withdrawal</th>
                                                        <th>Date</th>
                                                        {/* <th>Report</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data && data?.length
                                                        ? data.map((ele, i) => {
                                                            let ind =
                                                                currentPage * pageSize - pageSize + (i + 1);
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <span className="d-flex justify-content-center">
                                                                            {ind}
                                                                        </span>
                                                                    </td>
                                                                    {/* <td>
                                                                        <button className="btn-primary px-1 rounded" onClick={() => updateWithdrwalStatus(ele.address)}>
                                                                            {ele.withdrawal_status === true ? (
                                                                                <>
                                                                                    <div className="m-1 text-white"><i className="fa fa-check text-white" /></div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="m-1 text-danger"><i className="fa fa-times text-danger" /></div>
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </td> */}
                                                                    {/* <td>
                                                                        <button className="btn-primary px-1 rounded" onClick={() => updateBlockStatus(ele.address)}>
                                                                            {ele.roi_status === true ? (
                                                                                <>
                                                                                    <div className="m-1 text-white"><i className="fa fa-check text-white" /></div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="m-1 text-danger"><i className="fa fa-times text-danger" /></div>
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </td> */}
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.address)}
                                                                        </a>
                                                                        {ele.address ? <Copy data={ele.address} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.ref_address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.ref_address)}
                                                                        </a>
                                                                        {ele.ref_address ? <Copy data={ele.ref_address} /> : ""}
                                                                    </td>
                                                                    <td>{ele.t_staking ? ele.t_staking : "-"}</td>
                                                                    <td>
                                                                        <span className="d-flex justify-content-center">
                                                                            {ele?.team ? ele?.team : "0"}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-flex justify-content-center">
                                                                            {ele?.level_users.length ? ele?.level_users.length : "0"}
                                                                        </span>
                                                                    </td>
                                                                    <td>{ele.rank ? ele.rank : "-"}</td>
                                                                    <td>
                                                                        <span className="d-flex justify-content-center">
                                                                            {ele?.t_withdrawal
                                                                                ? ele?.t_withdrawal
                                                                                : "0"}
                                                                        </span>
                                                                    </td>
                                                                    <td>{ele.datetime}</td>
                                                                    {/* <td>
                                                                        <span className="me-3">
                                                                            <Link
                                                                                className="edit-appointment"
                                                                                type="button"
                                                                                to={`/userreport/${ele.address}`}
                                                                            >
                                                                                <i
                                                                                    className="fa fa-book fs-18"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </Link>
                                                                        </span>
                                                                    </td> */}
                                                                </tr>
                                                            );
                                                        })
                                                        : ""}
                                                </tbody>
                                            </table>
                                            {loading ? (
                                                <div className="text-center">
                                                    <p>Loading...</p>
                                                </div>
                                            ) : !data || !data.length ? (
                                                <div className="text-center">
                                                    <p>No data found.</p>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className='d-flex justify-content-center py-4'>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                            {/* <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                </ul>
                                            </nav> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Scroll - vertical dynamic Ends*/}
                        </div>
                        {/* Container-fluid starts*/}


                    </div>

                    <Footer />
                </div>

            </main>

        </>
    )
}
